import * as PrismicTypes from '@prismicio/types'
import React from 'react'
import styled, { css } from 'styled-components'

import PageSliceZone from '../components/SliceZone/PageSliceZone'
import { IErrorPage } from '../types/prismic/pages/errorPage'
import { Language } from '../types/Types'
import { getSingleByUid } from '../utils/prismic/data-helpers'
import { getDefaultPageData } from '../utils/prismic/RouteHelpers'

interface IProps {
	errorPage: IErrorPage.IProps
	previewData: PrismicTypes.Ref
	locale: Language
}

const Index: React.FC<IProps> = ({ errorPage }) => {
	return (
		<StyledPageContainer>
			<div>
				<h1>{errorPage.data.title}</h1>
			</div>
			<PageSliceZone slices={errorPage.data.body} />
		</StyledPageContainer>
	)
}
export const getStaticProps = async ({ previewData, locale }: IProps) => {
	const { ref } = previewData || {}

	const errorPage = await getSingleByUid('error_page', 'error-page-404', ref, locale)

	return {
		props: {
			errorPage,
			...(await getDefaultPageData(ref, locale)),
			pageId: errorPage.id,
		},
	}
}
export default Index

const StyledPageContainer = styled.main`
	${({ theme: { colors } }) => css`
		display: flex;
		flex-direction: column;
		max-width: 780px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 96px;
		padding: 0 15px;
		@media (min-width: 768px) {
			// ? seems redundant, but copied from original stylesheet
			width: auto;
		}
		@media (min-width: 1200px) {
			width: 1170px;
		}

		@media (min-width: 992px) {
			width: auto;
		}

		> h1 {
			@media only screen and (min-width: 768px) {
				margin-top: 50px;
				margin-bottom: 30px;
			}
		}
		p,
		li {
			font-weight: 400;
		}

		a {
			font-weight: 500;
			text-decoration: none;
			cursor: pointer;
			color: ${colors.gold400};
		}
	`}
`
